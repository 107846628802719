@import '../../assets/styles/variables';
.info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 16px;

  &::after {
    content: ' ';
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100vw;
    height: 8vh;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
  .wrap {
    max-width: 554px;
    margin: auto;

    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      max-width: 588px;
    }
    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      max-width: 956px;
    }
  }
  .container {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 8vh;

    @media screen and (orientation: portrait) {
      padding-bottom: 7.5vh;
      position: relative;
    }

    &::-webkit-scrollbar-track {
      background-color: #010101;
    }

    &::-webkit-scrollbar {
      width: 8px;

      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        width: 13px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        width: 13px;
      }
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--game-color);
    }
  }

  .featureIcon {
    text-align: center;
    user-select: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    margin-top: 0px;
    transform: scale(0.75);
  }
  .fishIcon {
    text-align: center;
    user-select: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    margin-top: 0px;
    transform: scale(0.75);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 60vw;
      @media (orientation: landscape) {
        width: 45vw;
      }
    }
    p {
      max-width: 100%; /* 親要素を超えない */
      font-weight: 700;
      font-size: clamp(12px, 3vw, 48px);
      color: #fff;
      text-align: center;
      margin: 0 auto 10px;

      span {
        color: var(--game-color);
      }
    }
  }
}
/* 横長画面での調整 */

.title {
  color: var(--game-color);
  font-size: 32px;
  margin: 32px 0;
  text-align: center;
  font-weight: 900;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 44px;
    margin: 64px 0;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 44px;
    margin: 64px 0;
  }
}

.subtitle {
  color: var(--game-color);
  font-size: 26px;
  margin: 32px 0;
  text-align: center;
  font-weight: 900;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 32px;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    margin: 64px 0;
  }
}

.row {
  display: flex;
  margin: 16px 0;
  flex-wrap: wrap;

  &.center {
    justify-content: center;
  }

  .col {
    width: 100%;
    padding: 0 16px;
  }
}

.p {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
  text-align: left;
  margin: 0 0 10px;

  @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
    font-size: 22px;
  }
  @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
    font-size: 22px;
  }

  &.center {
    text-align: center;
  }

  span {
    color: var(--game-color);
  }

  italic {
    color: var(--game-color);
    font-style: italic;
    letter-spacing: 0px;
    padding: 5px;
  }
}

.image {
  display: flex;
  flex-direction: column;

  img,
  svg {
    display: block;
    width: 56px;
    height: 56px;
    margin: 0 auto 8px;
  }

  &__text {
    font-size: 16px;
    color: #fff;
    text-align: center;
  }
}

.paytable-list-scatter {
  display: grid;
  grid-template-rows: repeat(2, 1fr); /* 2行 */
  grid-template-columns: repeat(2, 1fr); /* 2列 */
  gap: clamp(3px, 1vw, 10px); /* gapを最小5px、最大10pxに制限 */
  width: 50vw; /* グリッド全体の幅を画面幅の50%に調整 */
  margin: 0 auto; /* 横方向で中央揃え */
  position: relative; /* レイアウト用の相対配置 */
  max-width: 100%; /* 上限を100%に制限 */

  img {
    display: block;
    width: 100%;
    height: auto;
    //aspect-ratio: 1 / 1;
    //    margin-top: 10px;
  }
}
.paytable-list-caption {
  color: #fff;
  font-size: clamp(16px, 3vw, 36px);
  font-weight: 700;
  text-align: center;
  padding: 0;
  margin: auto;
}

.paytable-list {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto;
  justify-content: center;

  &__item {
    //grid-column: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    width: 100%;
    align-items: center;
    padding: 0 8px 16px;

    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      padding: 0 16px 32px;
    }
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      padding: 0 16px 32px;
    }

    img {
      display: block;
      grid-row: 1;
      grid-column: 1;
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      margin: 0;
    }

    .content {
      grid-row: 1 / span 2;
      grid-column: 2;
      white-space: nowrap;
      width: 50%;
      padding-left: 8px;
      align-items: flex-start;
      justify-content: center;
      font-size: 14px;
      color: var(--game-color);
      @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
        font-size: 20px;
      }
      @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
        font-size: 20px;
      }

      .multiplier {
        color: #fff;
      }
    }
  }
  p.caption {
    color: #fff;
    font-size: clamp(16px, 3vw, 36px);

    //    font-size: 36px;
    font-weight: 700;
    text-align: center;
    padding: 0;
    margin-top: 0px;

    @media screen and (min-width: $tablet-landscape-min) and (orientation: landscape) {
      font-size: 32px;
    }
    @media screen and (min-width: $tablet-portrait-min) and (orientation: portrait) {
      font-size: 32px;
    }
  }
}

.payLines {
  display: block;
  width: 100%;
  margin: 0 auto;

  .image-container {
    display: flex;
    justify-content: space-between;
    width: 25%;
    height: auto;
    margin: auto;
  }

  .image-container img {
    width: 100%;
    padding: 0 0.5vw;
    margin: 0 auto;
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
  }
}

.buttonBehaviors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;

  &__item {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    color: #fff;
    padding: 0 8px 16px;
    text-align: center;

    @media screen and (orientation: portrait) {
      width: 50%;
    }

    svg {
      display: block;
      width: 56px;
      height: 56px;
      margin-bottom: 8px;
      user-select: none;
      -webkit-user-select: none;
      -webkit-touch-callout: none;
    }
    &.start {
      width: 100%;
      svg {
        width: 210px;
        // height: 128px;
      }
    }
    &.spin {
      margin-left: 25%;
      svg {
        width: 128px;
        height: 128px;
      }
    }
    &.stop {
      margin-right: 25%;
      svg {
        width: 128px;
        height: 128px;
      }
    }

    @media screen and (orientation: portrait) {
      &.start,
      &.spin,
      &.stop {
        margin: 0 !important;
      }
    }
  }
}
