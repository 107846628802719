@import './variables.scss';
@import './popup.scss';

* {
  user-select: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  height: 100%;
}

body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family), sans-serif;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.svg-image {
  display: block;
  width: 100%;
  height: auto;
  user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.svg-color {
  fill: var(--game-color);
}

.btn-group {
  display: flex;
  justify-content: space-between;
}

.uiBtn {
  &.uiBtn_active {
    background-color: var(--game-color) !important;
  }
}

.uiSwitch {
  &.uiSwitch_checked {
    :before {
      background-color: var(--game-color) !important;
    }
  }
}

.uiSlider {
  .uiSlider_progress {
    background-color: var(--game-color) !important;
  }
  input[type='range']::-webkit-slider-thumb {
    background-color: var(--game-color) !important;
  }
  input[type='range']::-moz-range-thumb {
    background-color: var(--game-color) !important;
  }
  input[type='range']::-ms-thumb {
    background-color: var(--game-color) !important;
  }
}
